import {CartSectionSettings} from '@/cart/types';

const placeholderCartSectionSettings = {
  cart_title: 'Your Cart:',
  checkout_button_label: 'CHECKOUT',
  donation_heading: 'Every time you buy Earth Breeze we donate 10 FREE washes.',
  donation_select_label: 'Where would you like us to donate?',
  donation_select_options: {
    options: [
      'Homeless Shelters',
      'Disaster and Global Relief',
      'Animal Care',
      "Women's Shelters",
      'Veterans',
    ],
  },
  donation_terms_conditions: 'This donation is absolutely free for you.',
  empty_cart_title: 'Your cart is empty.',
  shipping_line_label: 'Carbon Offset Shipping',
  subtotal_line_label: 'Subtotal',
  free_shipping: 'FREE',
} satisfies CartSectionSettings;

export default placeholderCartSectionSettings;
